@import "_default.scss_include-mixins";

/*
Version 0.125

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	25-08-2020
	- [CHANGE] SCSS Style 
2019
	16-05-2019
	- [CHANGE] Some thing added for iOS rounder corners.
2018
	11-06-2018
	- [CHANGE] Link to loading.svg was wrong, fixed now.
2017
	28-11-2017
	- [CSS] input[type="number'] added.
	- [CSS] .indicator css is default, custom styling is moved to global.css
	03-11-2017
	- [TINYMCE] Added new style for TinyMCE Editor
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

input[type="text"],
input[type="password"],
input[type="number"],
textarea,
select {
	font-size:14px;
	line-height:20px;
	width:100%;
	padding:10px 15px;
	color:#000;
	background-color:#fff;
	border:0;
	/* -webkit-appearance: none;	/* iOS rutn off border-radius */
	border-radius: 0;				/* iOS rutn off border-radius */
}

	/*
	Hide the nummber arrows
	*/
	input[type="number"]::-webkit-outer-spin-button, 
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	 
	input[type="number"] {
		-moz-appearance: textfield;
	}

textarea {
	overflow:auto;
	resize:none;
}

input[type="button"] {
	font-size:14px;
	padding:5px 10px;
	color:#fff;
	background-color:#000;
	border:0;
	text-align:center;
	cursor:pointer;
}

.form {
	float:left;
	width:100%;
	
	.form-row {
		float:left;
		width:100%;
	}

	.form-block {
		position:relative;
		float:left;
		width:100%;
		margin-bottom:20px;
		padding:20px;
		background-color:#eaeaea;
	}

	.element-row {
		position:relative;
		float:left;
		width:100%;
		margin-bottom:5px;
		color:#000;
		
		.form-input {
			float:left;
			border:0;
			background-color:#d7d7d7;
			padding-left:10px;
			padding-right:10px;
			font-size:14px;

			@include sm {
				font-size:13px;
			}

			&.ok {
			}

		}

		/*
		Indicator
		*/
		.indicator {
			position:absolute;
			top:0;
			right:0;
			height:100%;
			width:4px;
		}

		/*
		Checkbox
		*/
		.checkbox {

			span {
				float:left;
				margin-left:10px;
				width:80px;
			}

			.box {
				float:left;
				width:15px;
				margin-top:5px;
			}

		}

		/*
		Radiobutton
		*/
		.radiobutton {

			.radio {
				float:left;
				width:15px;
				margin-top:5px;
			}

			span {
				float:left;
				margin-left:10px;
				width:80px;
			}

		}

		.radiobutton_specific-element:last-child {
			margin-bottom:0;
		}

		/*
		Textarea
		*/
		textarea {
			height:200px;
		}

		/*
		Uploads
		*/
		.upload  {

			.left {
				float:left;
				width:200px;
			}

			.right {
				float:left;
				width:100px;
				margin-left:10px;

				input {
					margin-bottom:5px;
				}

			}

			.upload-process {
				display:none;
			}

			.upload-info {
				float:left;
				margin-top:10px;
			}

		}

		/*
		Submit button
		*/
		.form-submit {
			
			text-decoration:none;
			cursor:pointer;

			&:hover {
				text-decoration:underline;
			}

			&.active {
				padding-left:30px !important;
				padding-left:50px;
				background-image:url(../img/static/assets/loading.svg);
				background-position:10px;
				background-repeat:no-repeat;
				background-size:13%;
			}

			&:hover {
				text-decoration:underline;	
			}

		}
		
	}

	/*
	Image Gallery
	*/
	.form-image-gallery {

		.image-gallery-image-upload {
			float:left;
			margin:10px;
			width:180px;
			text-align:center;
			margin-left:0;
			padding:5px 10px;
			color:#fff;
			font-weight:bold;
			background-color:#000;
			cursor:pointer;
		}

		.upload-process {
			display:none;
			font-size:14px;
		}

		.images {
			clear:both;
			float:left;
			width:100%;

			.image {
				float:left;
				position:relative;
				border:5px solid #ccc;
				background-color:#e1e1e1;
				width:180px;
				height:180px;
				margin-bottom:10px;
				margin-right:10px;
				background-repeat:no-repeat;
				background-position:center;
				background-size:cover;

					.image-gallery-image-delete {
						position:absolute;
						right:5px;
						top:5px;
						line-height:13px;
						font-size:16px;
						padding:5px;
						background-color:#888;
						color:#fff;
						cursor:pointer;
						border:1px solid #999;
					}

			}

		}

	}

}

/*
TinyMCE Editor styles
*/
.mce-path {
    display: none !important;
}