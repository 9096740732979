@import "_default.scss_include-mixins";

/*
Version 0.125

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2019
	27-0-2019
	- [ADDED] Anti Aliasing moved here.
2018
	15-12-2018
	- [ADDED] SVG Logo Finally
	26-02-2018
	- [ADDED] New class to prevent scrolling on body when .menu-open
2017
	27-11-2017
	- [CHANGED] The background position of .go-preload-img is now center !important overruling all.
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

* {
	box-sizing:border-box;
	
	/*
	Anti-aliasing
	*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	outline: 0;
}

html {
}

body {
	float:left;
	width:100%;
	min-width:300px;
	color:#fff;
}

.error {
	font-family:'Open Sans', sans-serif;
	font-size:14px;
	line-height:20px;
	padding:20px;
}

/*
Grid extension: No Gutter
*/
.no-gutter {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.no-container {
	width:100%;
}

/*
Body no scrolling
*/
body.no-scroll {
	overflow: hidden;
}

/*
GO! IMAGES LOADER
*/

.go-preload-img {
	width:100%;
	height:100%;
	background-image:url(../img/static/assets/loading.svg);
	background-position:center !important;
	background-repeat:no-repeat;
	background-size:10%;
	background-color:#fff;
	//min-height:500px;

	@include sm {
		min-height:auto;
	}

}

	.go-preload-img.no-fadein {
	}
	
	.go-preload-img.no-preloader {
		background-image:none;
	}
	
.responsive-background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 24%;
  padding-bottom: 56.25% /* 16:9 ratio */

 	 img {
	  display: none;
	}

}


/* NOT FOUND PAGE */
/* NOT FOUND PAGE */
/* NOT FOUND PAGE */
/* NOT FOUND PAGE */

.page.content--notfound {
}

/*
Overwrite: Flag images
*/
.iti-flag {
	background-image: url("../img/static/assets/flags.png");
}
 
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {
  	background-image: url("../img/static/assets/flags@2x.png");
  }
}

/* EMBED */
/* EMBED */
/* EMBED */
/* EMBED */

.videoplayer {
	position:relative;
	padding-bottom:56.25%;
	/** padding-top:30px; **/
	height:0;
	overflow:hidden;	
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;

	@include sm {
		min-height:auto;
	}

	iframe, 
	object,
	embed {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		border:0;
	}

	.button {
		position:absolute;
		top:50%;
		left:50%;
		margin-left:-100px;
		margin-top:-80px;
		width:200px;
		background-color:transparent;
		border:0;
		text-shadow:0 0 5px rgba(0,0,0,0.6);

		@include sm {
			margin-left:-100px;
			margin-top:-40px;
		}

		.icon {
			clear:both;
			float:left;
			width:100%;
			text-align:center;
			color:#fff;
			font-size:80px;
			margin-left:0;

			@include sm {
				font-size:30px;
			}
		}

		.label {
			clear:both;
			float:left;
			margin-top:20px;
			width:100%;
			text-align:center;
			color:#fff;
			font-weight:bold;
			text-transform:uppercase;

			@include sm {
				font-size:14px;
			}
		}

	}

}

.development-notice {
	position:fixed;
	bottom:0;
	right:0;
	padding:10px;
	background-color:rgba(0,0,0,0.3);
	font-size:11px;
	color:#fff;
	border-top-left-radius:10px;
	z-index:9999;
}