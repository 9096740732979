@import "_default.scss_include-mixins";

/*
Version 0.155

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	25-08-2020
	- [CHANGE] SCSS Style
	22-04-2020
	- [ADDED] Change some stuff.
	
2019
	24-05-2019
	- [ADDED] Popup is now relative
2018
	29-12-2018
	- [CHANGED] Added display:none to .popup-scroller.
	10-12-2018
	- [ADDED] Some CSS for .popup-scroller.
	09-12-2018
	- [ADDED] Added responsive .close.
	04-09-2018
	- [CHANGED] Changed some CSS things for better popup scrolling.
	28-02-2018
	- [ADDED] A new extra div is added, .popup-view for preventing the body to scroll when popup is higher then the window height.
2017
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* 
.popup-outside {
	display:none;
	position:fixed;
	left:0;
	top:0;
	bottom:0;
	right:0;
	z-index:8000;
}
*/

.popup {
	position:relative;
}

.popup-scroller {
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:auto;
	background-color:rgba(0,0,0,0.80);
	z-index:8500;
}

	.popup-loader {
		display:none;
		position:absolute;
		top:0;
		left:0;
		left:-9999px;
		z-index:200;
	}

	.popup-background { 
		display:none;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:100;
		overflow:auto;
		cursor:pointer;
	}

/*
Popup inside
*/
.popup {

	.header {
		clear:both;
		float:left;
		width:100%;
	}
		
	.content {
		clear:both;
		float:left;
		width:100%;
		margin-top:10px;
	}
	
	.close {
		position:absolute;
		width:30px;
		height:30px;
		top:-35px;
		right:-35px;
		color:#fff;
		cursor:pointer;
		padding:5px 10px;
		border-radius:50%;
		background-color:#fff;
		transform:rotate(45deg);
	
			.label {
				position:relative;
				top:0;
				left:3px;
				width:30px;
			
				.row {
					position:absolute;
					height:2px;
					background-color:#000;
				
				&.row-1 {
					top:9px;
					left:7px;
					width:20px;
					transform:rotate(0deg);
				}
				&.row-2 {
					top:9px;
					left:7px;
					width:20px;
					transform:rotate(90deg);
				}

			}

		}

	}

}