@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	p,ul li {
		padding:0;
		margin:0;
		font-size:20px;
		line-height:35px;
		color:#000;

		a {
			color:#888;
		}

		@include sm {
			font-size:14px;
			line-height:24px;
		}

	}

	h2 {
		display: flex;
	    width: 100%;
	    justify-content: center;
	    align-items: center;
	    text-align: center;
	    color:#000;
	    text-transform:uppercase;
	    font-family:'Poppins',sans-serif;
	    font-weight:700;

	    @include sm {
			font-size:14px;
			line-height:24px;
		}

	    &:before,
		&:after {
			content: '';
		    margin: 0 20px 0 0;
		    flex: 1 0 20px;
		    border-top: 2px solid #000;
		}

	    &.white {
	    	color:#fff;

	    	&:before,
			&:after {
			    border-top: 2px solid #fff;
			}
	    }

		&:after {
		   margin: 0 0 0 20px;
		}

	}

	h3 {

		&.corona {
		display:inline-block;
		padding:10px 20px;
		border-radius:5px;
		background-color:#f90;
		}

	}

}