@import "_default.scss_include-mixins";

.page.content--offerte {

	.pricing {

		float:left;
		width:100%;
		margin-bottom:40px;
		text-align:center;
	
		.column {
			display:inline-block;
			width:300px;
			margin:10px;
			vertical-align: top;

			&.column-1 {
				.title {
					padding:10px;
					padding-top:20px;
					padding-bottom:20px;
					background-color:#96C2EA;
					border-top-left-radius:10px;
					border-top-right-radius:10px;
					font-size:22px;
				}
			}
			&.column-2 {
				.title {
					padding:10px;
					padding-top:20px;
					padding-bottom:20px;
					background-color:#1779CF;
					border-top-left-radius:10px;
					border-top-right-radius:10px;
					font-size:22px;
				}
			}
			&.column-3 {
				.title {
					padding:10px;
					padding-top:20px;
					padding-bottom:20px;
					background-color:#002F80;
					border-top-left-radius:10px;
					border-top-right-radius:10px;
					font-size:22px;
				}
			}

			.rows {
				float:left;
				width:100%;

				.row-x {
					float:left;
					width:100%;
					padding:5px;
					padding-top:14px;
					padding-bottom:14px;
					text-align:center;
					font-size:16px;
					line-height:16px;
					color:#000;

					&:nth-child(odd) {
						background-color:#e1e1e1;
					}
					&:nth-child(even) {
						background-color:#F9F9F9;
					}

					&.row-y {
						padding:20px;
						line-height:40px;
					}

				}

			}

			.price {

				.button.button-1 .icon {
					margin-top:0;
				}

				&.price-1 {
					padding:10px;
					padding-top:20px;
					padding-bottom:20px;
					background-color:#96C2EA;
					border-bottom-left-radius:10px;
					border-bottom-right-radius:10px;
					font-size:22px;
				}
				&.price-2 {
					padding:10px;
					padding-top:20px;
					padding-bottom:20px;
					background-color:#1779CF;
					border-bottom-left-radius:10px;
					border-bottom-right-radius:10px;
					font-size:22px;
				}
				&.price-3 {
					padding:10px;
					padding-top:20px;
					padding-bottom:20px;
					background-color:#002F80;
					border-bottom-left-radius:10px;
					border-bottom-right-radius:10px;
					font-size:22px;
				}

				.from {
					float:left;
					width:100%;
					margin-top:20px;
					font-size:14px;
					font-style: italic;
				}

				.amount {
					float:left;
					width:100%;
					margin-top:0px;
					margin-bottom:20px;
					font-family:'Open Sans',arial;
					font-weight:800;
					font-size:60px;
				}

			}

		}

	}

}