@import "_default.scss_include-mixins";

.page.content--portfolio-item {

	.rest {
		padding-bottom:80px;

		.play {
			float:left;
			position:relative;
			width:100%;
			height:400px;
			background-color:#e1e1e1;
			background-position:center;
			background-size:cover;
			border-radius:5px;

			.button {
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				padding:20px;
				padding-left:30px;
				padding-right:30px;
				background-color:#fff;
				color:#000;
				font-size:20px;
				font-family:'Poppins',arial;
				box-shadow:0 0 30px rgba(0,0,0,1);
				border-radius:5px;

				.label {
					float:left;

					@include sm {
						float:left;
						width:100%;
						text-align:center;
					}

				}

				.icon {
					float:left;
					margin-left:20px;
					margin-top:0px;
					width:30px;
					height:22px;
					overflow:hidden;

					@include sm {
						width:100%;
						text-align:center;
						margin-left:0;
						margin-top:5px;
					}

					svg {
						width:30px;
						height:26px;
						fill:#000;
						opacity:0.4;

						@include sm {
							float:none;
							display:inline-block;
						}

					}

				}

				&:hover {
					background-color:#000;
					color:#fff;

					svg {
						opacity:1;
						fill:#fff;
					}

				}

			}

		}

	}

}