@import "_default.scss_include-mixins";

.color-white {
	color:#fff !important;
}

.color-black {
	color:#000 !important;
}

.color-gray {
	color:#888 !important;
}

.color-blue {
	color:$color-blue !important;
}

.color-yellow {
	color:$color-yellow !important;
}