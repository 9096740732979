@import "_default.scss_include-mixins";

.page.content--faq {

	.faqs {
		
		.item {

			float:left;
			width:100%;
			margin-bottom:10px;

			.left {
				float:left;

				.icon {
					float:left;
					padding:10px;
					background-color:#000;

					.minus {
						display:block;
					}

					.plus {
						display:none;
					}

					svg {
						height:20px;
						width:20px;
						fill:#fff;
					}

				}

			}

			.right {
				float:left;
				margin-left:20px;
				margin-top:7px;
				width:600px;

				.question {
					float:left;
					width:100%;
					color:#000;
					font-weight:bold;
					cursor:pointer;
				}

				.answer {
					display:none;
					float:left;
					margin-top:20px;
					width:100%;
					color:#000;
					line-height:25px;
					padding:10px;
					background-color:#e1e1e1;
					border-radius:5px;
				}

			}

		}

	}

}