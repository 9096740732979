@import "_default.scss_include-mixins";

.slideshow-clients {
	float:left;
	width:100%;
	margin-top:40px;
	margin-bottom:40px;

	@include sm {
		margin-top:20px;
	}
	
}

	.slideshow-clients .logo {
		float:left;
		width:100%;
		height:200px;
		background-repeat:no-repeat;
		background-size: contain;
		background-position: center;

		@include sm {
			height:70px;
		}

	}