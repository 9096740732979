@import "_default.scss_include-mixins";

/*
Version 0.125

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	25-08-2020
	- [CHANGE] SCSS style
2018
	10-04-2018
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

.helper-video {
	display:inline-block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}