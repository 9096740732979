@import "_default.scss_include-mixins";

.include_team {

	float:left;
	width:100%;	
	min-height:400px;
	padding:40px;
	padding-top:80px;
	padding-bottom:80px;
	background-image:url('../img/static/team-bckgrnd.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size:cover;
	background-color:#000;
	text-align:center;

	.member {

		display:inline-block;
		width:200px;
		margin:40px;

		@include sm {
			margin:0;
			width:100%;
			margin-bottom:20px;
		}

		.image {
			float:left;
			width:100%;
			padding-top:100%;
			border-radius:50%;
			background-color:#fff;
			border:4px solid #fff;
			background-size:cover;
			background-repeat:no-repeat;
		}

		h2 {
			display:inline-block;
			font-size:20px;
			text-align:center;
			color:#fff;
		}

		a {
			display:inline-block;
			color:#fff;
			line-height:25px;
			text-align:center;
			text-decoration:none;
		}

	}

}