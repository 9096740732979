@import "_default.scss_include-mixins";

/*
Version 0.145

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	25-08-2020
	- [CHANGE] New Scss style
2019
	18-04-2019
	- [ADDED] New helper: Table
2018
	03-04-2018
	- [ADDED] .ml-20 for extra margin left
	18-02-2018
	- [ADDED] .click-through added for click through items
	05-02-2018
	- [ADDED] .clickable added for making elements use cursor:pointer.
2017
	08-12-2017
	- [ADDED] Extended the .padding-x and .margin-x styles.
	- [CSS] Added: .mt-5 for 5 pixels margin top
	03-11-2017
	- [CSS] Added: new helper for table-responsiveness: .table-responsive.
	24-10-2017
	- [CSS] Added: some new helpers for mobile margin top: .mt-xs-0 and .mb-xs-0
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

.clear { 
	clear:both;
}

/*
SHOW AND HIDE
SHOW AND HIDE
SHOW AND HIDE
SHOW AND HIDE
*/
.desktop { 

	display:block; 

	@include lg {
		display:none;
	}

}
.mobile {

	display:none; 

	@include lg {
		display:block;
	}

}

.hidden {
	display:none;
}

.inline {
	display:inline-block;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;           /* Non-prefixed version, currently not supported by any browser */
	outline: none;
}

/*
TEXT
TEXT
TEXT
TEXT
*/

.text-underline {
	text-decoration: underline;
}
	
/*
INTERACTION
INTERACTION
INTERACTION
INTERACTION
*/

.cursor-pointer {
	cursor:pointer;
}

.click-through {
	pointer-events: none;
}

/*
TABLES
*/

.helper-table {
	overflow-x:auto;
}


.table-responsive {

	overflow-x:auto;
 	
 	&::-webkit-scrollbar {
    	-webkit-appearance: none;
	}
	
	&::-webkit-scrollbar:vertical {
		width: 12px;
	}
	
	&::-webkit-scrollbar:horizontal {
		height: 12px;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, .5);
		border-radius: 10px;
		border: 2px solid #ffffff;
	}
	
	&::-webkit-scrollbar-track {
		border-radius: 10px;  
		background-color: #ffffff; 
	}

}

/* MARGINS & PADDING */
/* MARGINS & PADDING */
/* MARGINS & PADDING */
/* MARGINS & PADDING */

.m-0 {
	margin:0;
}
.mt-0 {
	margin-top:0;
}
.mt-10 {
	margin-top:10px;
}
.mt-20 {
	margin-top:20px;
}
.mt-30 {
	margin-top:30px;
}
.mt-40 {
	margin-top:40px;
}
.mt-50 {
	margin-top:50px;
}
.mt-60 {
	margin-top:60px;
}
.mt-70 {
	margin-top:70px;
}
.mt-80 {
	margin-top:80px;
}

.mb-0 {
	margin-bottom:0;
}
.mb-10 {
	margin-bottom:10px;
}
.mb-20 {
	margin-bottom:20px;
}
.mb-30 {
	margin-bottom:30px;
}
.mb-40 {
	margin-bottom:40px;
}
.mb-50 {
	margin-bottom:50px;
}
.mb-60 {
	margin-bottom:60px;
}
.mb-70 {
	margin-bottom:70px;
}
.mb-80 {
	margin-bottom:80px;
}

.ml-0 {
	margin-left:0;
}
.ml-10 {
	margin-left:10px;
}
.ml-20 {
	margin-left:20px;
}
.ml-30 {
	margin-left:30px;
}
.ml-40 {
	margin-left:40px;
}
.ml-50 {
	margin-left:50px;
}
.ml-60 {
	margin-left:60px;
}
.ml-70 {
	margin-left:70px;
}
.ml-80 {
	margin-left:80px;
}

.p-0 {
	padding:0;
}
.pt-0 {
	padding-top:0;
}
.pt-10 {
	padding-top:10px;
}
.pt-20 {
	padding-top:20px;
}
.pt-30 {
	padding-top:30px;
}
.pt-40 {
	padding-top:40px;
}
.pt-50 {
	padding-top:50px;
}
.pt-60 {
	padding-top:60px;
}
.pt-70 {
	padding-top:70px;
}
.pt-80 {
	padding-top:80px;
}

.pb-10 {
	padding-bottom:10px;
}
.pb-20 {
	padding-bottom:20px;
}
.pb-30 {
	padding-bottom:30px;
}
.pb-40 {
	padding-bottom:40px;
}
.pb-50 {
	padding-bottom:50px;
}
.pb-60 {
	padding-bottom:60px;
}
.pb-70 {
	padding-bottom:70px;
}
.pb-80 {
	padding-bottom:80px;
}

/*
IMAGES
IMAGES
IMAGES
IMAGES
*/

.img-maxwidth {
	max-width:100%;	
}

.img-circle {
	border-radius:50%;
}

.no-repeat {
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;
}

/*
ROTATE
ROTATE
ROTATE
ROTATE
*/

.rotate-180 {
	transform:rotate(180deg);
}
.rotate--180 {
	transform:rotate(-180deg);
}
.rotate-90 {
	transform:rotate(90deg);
}
.rotate--90 {
	transform:rotate(-90deg);
}

/*
FLOATS
FLOATS
FLOATS
FLOATS
*/

.float-left {
	float:left;
}

.float-right {
	float:right;
}

.float-none {
	float:none;
}

/*
ALIGN
ALIGN
ALIGN
ALIGN
*/

.align-left {
	text-align:left !important;
}
.align-center {
	text-align:center !important;
}
.align-right {
	text-align:right !important;
}