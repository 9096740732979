@import "_default.scss_include-mixins";

.button {
	transition:all 0.2s ease-in-out;
	cursor:pointer;
	
	&.button-1 {
		float:left;
		width:100%;
		text-decoration:none;

		text-align:center;
		padding:7px;
		padding-left:15px;
		padding-right:10px;
		background-color:#202020;

		.icon {
			float:right;
			margin-left:20px;
			margin-top:5px;

			svg {
				width:15px;
				height:15px;

				fill:#fff;
		
			}

		}

		.label {
			float:left;
			margin-top:2px;
			font-family:'Open Sans',arial;
			font-size:17px;
			color:#fff;
			text-decoration:none;
		}

		&:hover,
		&.active {
			background-color:#000;
		}

		&.cta {
			background-color:#395D03;

			&:hover {
				background-color:#000;
			}

		}

		&.inline {
			float:inherit;
			width:auto;
		}
		
	}

}