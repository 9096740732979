@import "_default.scss_include-mixins";	 

header {

	.mobile-button {
		display:none;
		position:fixed;
		top:20px;
		right:20px;
		padding:20px;
		padding-top:10px;
		padding-bottom:10px;
		border-radius:2px;
		background-color:rgba(0,0,0,0.4);
		z-index:10;
		cursor:pointer;

		@include sm {
			display:block;
		}

		span {
			font-family:'Open Sans',arial;
			color:#fff;
			font-size:12px;
			font-weight:bold;
		}

	}

	nav {
		position:fixed;
		width:100%;
		background-color:rgba(0,0,0,0.8);
		padding:10px;
		text-align:center;
		z-index:1000;

		@include md {
			display:none;
		}

		a {
			position:relative;
			display:inline-block;
			text-decoration:none;
			color:#fff;
			margin-left:10px;
			margin-right:10px;
			font-size:15px;
			letter-spacing:0.5px;
			z-index:1000;
			
			.line {
				position:absolute;
				width:100%;
				height:2px;
				bottom:-11px;
				left:0;
				background-color:#fff;
				opacity:0;
			}

			&:hover > .line {
				opacity:1;
			}

			&.active > .line {
				opacity:1;
			}

		}

		span {
			display:inline-block;
			height:12px;
			width:1px;
			background-color:#fff;
			opacity:0.2;
		}
		
	}
	
}