@import "_default.scss_include-mixins";

/*
Version 0.120

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	25-08-2020 
	- [CHANGE] SCSS Style
2018
	21-05-2018
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */

.popup--cookiewall {
	float:left;
	width:600px;
	padding:20px;
	background-color:#fff;

	@include sm {
		width:290px;
	}
	
	.content {
		margin-top:0 !important;
	}
	
	.textblock h2 {
		padding:0;
		margin:0;
	}

	.left {
		float:left;
		width:150px;

		@include sm {
			width:100%;
		}
	
		.option {
			float:left;
			width:100%;
			padding:5px;
			margin-top:5px;
			background-color:#e1e1e1;
		}
		
		input {
			float:left;
			padding:5px;
			margin-top:2px;
		}
		
		.title {
			float:left;
			margin-left:10px;
			margin-top:-3px;
			cursor:pointer;
		
			&:hover {
				text-decoration:underline;
			}

		}
		
		.submit {
			float:left;
			width:100%;
			margin-top:10px;
			background-color:#5A9C49;
			color:#fff;
			padding:5px;
			font-size:12px;
			line-height:18px;
			font-weight:bold;
			text-align:center;
			cursor:pointer;
		}

	}
	
	.right {
		float:left;
		width:350px;
		margin-top:5px;
		margin-left:20px;
		padding:20px;
		font-size:14px; 
		line-height:28px;
		border:1px solid #e1e1e1;

		@include sm {
			width:100%;
			margin-top:15px;
			margin-left:0;
		}
	
		h2 {
			font-size:18px;
			font-weight:bold;
			line-height:20px;
			margin:0;
			padding:0;
			margin-bottom:5px;
		}
		
		.tab {
			display:none;
		}

	}
	
	.rest {
		clear:both;
		float:left;
		margin-top:20px;
		width:100%;
	}

}