@import "_default.scss_include-mixins";

footer {	

	float:left;
	width:100%;
	padding:80px;
	background-color:#f2f2f2;
	min-height:400px;		

	@include sm {
		padding:10px;
		padding-top:30px;
	}

	.textblock-1 {
		margin-left:20px;

		@include sm {
			margin-left:0;
		}
	}

	.logo {
		position:absolute;
		top:50%;
		left:50%;

		width:200px;
		height:200px;

		transform:translate(-50%,-50%);

		@include sm {
			display:none;
		}

	}

	.button {
		margin-bottom:13px;

		@include sm {
			width:100% !important;
			margin-bottom:10px !important;
		}

		&.cta {
			margin:0;
			margin-right:20px;
		}

	}

}

.pro-footer {
	float:left;
	width:100%;
	background-color:#000;
	padding:10px;

	@include sm {
		text-align:center;
	}

	p {
		margin:0;
		padding:0;
		font-size:14px !important;

		@include sm {
			font-size:11px !important;
			text-align:center;
			margin-bottom:10px;
		}
	}

	a {
		margin-left:10px;
		margin-right:10px;

		@include sm {
			float:left;
			width:100%;
			text-align:center;
			margin-bottom:0;
		}
		
	}

}