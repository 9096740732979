.popup--terugbellen {
	float:left;
	width:500px;
}

	.popup--terugbellen .content {
		float:left;
		width:100%;
		background-color:#fff;
		padding:20px;
		padding-bottom:30px;
		border-radius:4px;
		text-align:left;
	}
	
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
	.popup--terugbellen {
		float:left;
		width:400px;
	}

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
	
	.popup--terugbellen {
		float:left;
		width:70vw;
		min-width:260px;
	}
	
}