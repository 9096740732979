@import "_default.scss_include-mixins";

/*
Portfolio items
*/
.include_portfolio {
	clear:both;
	float:left;
	width:100%;
	margin-top:30px;
	padding-bottom:80px;

	h2 {
		margin-bottom:40px;
	}

	.portfolio-item {
		position:relative;
		float:left;
		margin-bottom:10px;
		width:100%;

		.image {
			float:left;
			position:relative;
			width:100%;
			height:300px;
			border-radius:5px;
			overflow:hidden;
		
			.background {
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				background-color:rgba(0,0,0,0.1);
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center;
			}

		}

		.icon {

			position:absolute;
			top:50%;
			left:50%;
			width:50px;
			height:50px;
			transform:translate(-50%,200px);
			transition:all 0.4s;

			svg {
				fill:#fff;
				text-shadow: 0 0 10px rgba(0,0,0,0.5);
			}

		}

		.title {
			clear:both;
			float:left;
			width:100%;
			padding:20px;
			font-size:20px;
			padding-left:0;
			color:#000;
			text-decoration:none;
		}

		&:hover {

			.icon {
				transform:translate(-50%,-50%);
				width:100px;
				height:100px;
			}

		}

	}

}