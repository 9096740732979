@import "_default.scss_include-mixins";

/*
Portfolio items
*/
.include_popup {
	clear:both;
	float:left;
	width:100%;
	margin-top:30px;
	padding-bottom:80px;

	h2 {
		margin-bottom:40px;
	}

	img {
		border-radius:10px;
	}

}