@import "_default.scss_include-mixins";

/*
Version 0.120

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	25-08-2020
	- [CHANGE] SCSS Style
2018
	21-05-2018
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */

/**
None
**/

/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */

.cookiewall-bar {
	position:fixed;
	bottom:-200px;
	left:0;
	width:100%;
	z-index:9999;
	font-size:14px;
	color:#fff;
	background-color:rgba(0,0,0,0.9);

	a {
		color:#fff;
		text-decoration:underline;	
	}
	
	.left {
		float:left;
		padding:20px;
		width:80%;
		margin-top:10px;

		@include sm {
			float:left;
			padding:20px;
			width:100%;
			margin-top:10px;
		}
	}
	
	.right {
		float:right;
		width:20%;
		padding:20px;

			@include sm {
				clear:both;
				float:left;
				width:100%;
				padding:20px;
				padding-top:0;
				padding-bottom:40px;
				text-align:center;
			}
	
		.button {
			float:right;
			width:200px;
			padding:10px;
			color:#000;
			font-weight:bold;
			text-align:center;
			background-color:#fff;
			border-radius:3px;
			text-transform:uppercase;

			@include sm {
				float:none;
				display:inline-block;
			}

			&:hover {
				background-color:#000;
				color:#fff;
			}

		}
	}

}