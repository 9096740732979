@import "_default.scss_include-mixins";

body {
	font-family:'Open Sans', serif;
	background-color:#fff;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Marges
*/
.page-marge {
	float:left;
	width:100%;
	margin-top:120px;
	padding-bottom:40px;
}

/*
Link
*/
a.bottomline {

	text-decoration:none;
	//width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 1px), white 1px);
	background-repeat: no-repeat;
	background-size: 0% 100%;
	transition: background-size 0.5s;

	&.color-black {
		background-image: linear-gradient(transparent calc(100% - 1px), black 1px);
	}
	
	&:hover {
	    background-size: 100% 100%;
	}

}

/*
Iframe
*/
iframe {
	border:0;
}

/*
Hero
*/
.hero {
	float:left;
	position:relative;
	width:100%;
	height:100vh;
	min-height:700px;
	background-color:#000;

	@include sm {
		height:100vh;
		min-height:auto;
		height: calc(var(--vh, 1vh) * 100);
	}

	&.half {
		height:400px;
		min-height:400px;

		@include sm {
			height:50vh;
			min-height:50vh;
		}

		h1 {
			margin-top:0px !important;
			font-size:30px !important;
			margin-bottom:0 !important;
			letter-spacing:1px !important;

			@include sm {
				font-size:10px !important;
			}

		}

		.center {
			top:49%;

			@include sm { 
				top:34%;
			}
		}

		.logo {
			float:left;
			width:100%;

			svg {
				display:inline-block;
				width:150px;
				height:140px;
				margin-bottom:10px !important;

				@include sm {
					height:70px !important;
					width:70px !important;
				}
			}

		}

		.tour {
			height:400px !important;

			@include sm {
				height:50vh !important;
			}
		}

		.bckgrnd {
			height:400px !important;
			background-size:cover;
			background-position: center;

			@include sm {
				height:50vh !important;
			}

		}

	}

	.tour {

		position:relative;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color:#000;

		.overlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background-color:#000;
			opacity:0.6;
		}

		iframe {
			width:100%;
			height:100%;
		}

	}

	.logo {
		float:left;
		width:100%;
		text-align:center;

		svg {
			display:inline-block;
			width:180px;
			height:186px;
			fill:#fff;
			margin-bottom:20px;

			@include sm { 
				width:100px;
				height:100px;
			}
		}
		
	}

	.center {
		position:absolute;
		top:45%;
		left:50%;
		width:1000px;
		transform:translate(-50%,-50%);
		text-align:center;
		z-index:100;

		@include sm {
			width:250px;
		}

		h1 {
			float:left;
			width:100%;
			font-family: 'Poppins', sans-serif;
			font-size:80px;
			letter-spacing:10px;
			font-weight:600;
			padding:0;
			margin:0;
			margin-bottom:20px;
			font-weight:bold;
			text-transform: uppercase;
			text-shadow:0 0 0 50px rgba(0,0,0,0.6);

			@include sm {
				font-size:35px;
				letter-spacing:2px;
			}

		}

		.usps {

			clear:both;
			float:left;
			width:100%;
			text-align:center;

			.item {
				display:inline-block;

				@include sm {
					width:100%;
				}

				.icon {
					float:left;
					width:30px;
					margin-top:-5px;
					height:30px;
					margin-left:10px;

					@include sm { 
						width:10px;
						height:10px;
						margin-left:0;
					}

					svg {
						fill:#FF9900;
					}
				}

				h2 {
					float:left;
					padding:0;
					margin:0;
					margin-left:10px;
					font-size:20px;

					@include sm {
						font-size:11px;
					}
				}
				
			}

		}

	}

	.aanbieding {
		position:absolute;
		left:0;
		top:80%;
		background-color:#FF9900;
		padding:10px;
		padding-left:20px;
		padding-right:20px;
		z-index:100;
		text-decoration:none;

		@include sm {
			top:auto;
			bottom:10%;
			padding-left:10px;
			padding-right:10px;
		}

		.label {
			float:left;
			font-size:18px;
			font-weight:bold;
			color:#fff;

			@include sm {
				font-size:10px;
			}

		}

		.icon {
			float:left;
			margin-top:2px;
			margin-left:10px;
			width:20px;
			height:20px;

			@include sm {
				display:none;
			}

			svg {
				fill:#fff;
			}

		}

	}

	.line-down {
		position:absolute;
		width:2px;
		left:50%;
		bottom:0;
		margin-left:-1px;
		height:60px;
		background-color:#fff;
		z-index:100;

		.icon {
			position:absolute;
			top:-25px;
			margin-left:-9px;
			width:20px;
			height:20px;

			svg {
				fill:#fff;
			}

		}

	}

}

.usps {
	float:left;
	width:100%;
	text-align:center;

	.usp {
		display:inline-block;
		margin:20px;

		.icon {
			float:left;
			width:30px;
			height:30px;
			margin-top:-5px;
			margin-left:10px;

			svg {
				fill:#FF9900;
			}
		}

		.label {
			float:left;
			padding:0;
			margin:0;
			margin-left:10px;
			font-size:20px;
			color:#000;
		}
		
	}

}

/*
Rest
*/
.rest {
	float:left;
	width:100%;
	margin-top:40px;

	/*
	Items
	*/
	.items {
		float:left;
		width:100%;
		margin-top:40px;

		.item {
			clear:both;
			float:left;
			width:100%;
			margin-bottom:30px;

			@include sm {
				width:100%;
				text-align:center;
			}

			.icon-x {
				float:left;
				margin-top:10px;
				width:40px;
				height:40px;

				@include sm {
					float:left;
					display:inline-block;
					width:10%;
					margin-top:10%;
					height:auto;
				}

				svg {
					fill:#FF9900;
				}

			}

			.text {
				float:left;
				margin-left:30px;
				width:390px;
				color:#000;

				@include sm {
					width:70%;
					margin-left:20px;
					text-align:center;
				}

				.textblock-1 p {
					padding:0;
					margin:0;
					font-size:16px;
					line-height:22px;

					@include sm {
						font-size:13px;
						line-height:18px;
						text-align:left;
					}

				}

			}

			.button {
				float:right;
				margin-top:10px;
				width:200px;

				@include sm {
					float:left;
					width:auto;
					margin-left:16%;
					margin-top:10px;
					display:inline-block;
				}
			}
		}

	}

}